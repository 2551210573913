import React, { useContext, useEffect, useState } from "react"
import { Col, Form, FormGroup, Input, Label, Row, Button } from "reactstrap"
import FileUpload from "../FileUpload"
import NotificationEmails from "../NotificationEmails"
import { v4 as uuidv4 } from "uuid"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import Services from "../Services"
import { MerchantCreateContext } from "../index"

import { logoutUser, returnStateLogsTransactionUpdate } from "store/actions"
import axios from "axios"
//const axios = require('axios');

const images = [
  {
    type: "",
    formData: {},
  },
]

export const SecondConfigurations = props => {
  const {
    handleMerchantChange,
    merchantDetails,
    loadSubmitNowButton,
    toggleTabProgress,
    activeTabProgress,
  } = props

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const { register, handleSubmit, errors, reset, watch } = useForm()
  const { settingSecondStepError } = useContext(MerchantCreateContext)
  const [formDataFile, setFormDataFile] = useState({})
  const [fileLogo, setFileLogo] = useState(null)
  const [fileBanner, setFileBanner] = useState(null)
  const [files, setFiles] = useState([])
  const [fileUrls, setFileUrls] = useState([])

  useEffect(() => {}, [formDataFile])

  const handleLogo = e => {
    e.preventDefault()
    handleChange({ Logo: e.target.value })
    setFileLogo(e.target.files[0])

    const formDataLogo = new FormData()
    formDataLogo.append("file", e.target.files[0])
    formDataLogo.append("upload_preset", "payalllink")
    formDataLogo.append("cloud_name", "DeveloperQa")

    handleFileUpload("logo", formDataLogo)
  }

  const handleBanner = e => {
    e.preventDefault()
    handleChange({ Banner: e.target.value })
    setFileBanner(e.target.files[0])

    const formDataBanner = new FormData()
    formDataBanner.append("file", e.target.files[0])
    formDataBanner.append("upload_preset", "payalllink")
    formDataBanner.append("cloud_name", "DeveloperQa")

    handleFileUpload("banner", formDataBanner)
  }

  const handleFileUpload = async (type, formData) => {
    await fetch("https://api.cloudinary.com/v1_1/developerqa/image/upload", {
      method: "post",
      body: formData,
    })
      .then(resp => resp.json())
      .then(data => {
        if (type === "logo") {
          handleChange({ Logo: data.url })
        }

        if (type === "banner") {
          handleChange({ Banner: data.url })
        }
      })
      .catch(err => console.log(err))
  }

  const addFile = data => {
    setFiles([...files, data])
  }

  const addUrl = data => {
    setFileUrls([...fileUrls, data])
  }

  function checkJson(response) {
    return response.text().then(function (text) {
      return text ? JSON.parse(text) : {}
    })
  }

  function handleChange(changes) {
    //console.log(changes)
    handleMerchantChange({ ...merchantDetails, ...changes })
  }
  function handleChangePRSettings(changes) {
    //console.log(changes)
    handleChange({
      paymentGatewaySettings: {
        ...merchantDetails?.paymentGatewaySettings,
        ...changes,
      },
    })
  }

  function handleNotificationChange(id, notificationemail) {
    const newNotifications = [...merchantDetails.notificationEmails]
    const index = newNotifications.findIndex(i => i.id === id)
    newNotifications[index] = notificationemail
    handleChange({ notificationEmails: newNotifications })
  }

  function handleNotificationAdd() {
    const newNotification = {
      id: uuidv4(),
      email: "",
      date: new Date().toISOString(),
    }
    handleChange({
      notificationEmails: [
        ...merchantDetails.notificationEmails,
        newNotification,
      ],
    })
  }

  function handleNotificationDelete(id) {
    handleChange({
      notificationEmails: merchantDetails.notificationEmails.filter(
        i => i.id !== id
      ),
    })
  }

  function handleServiceChange(id, service) {
    const newServices = [...merchantDetails.services]
    const index = newServices.findIndex(i => i.id === id)
    newServices[index] = service
    handleChange({ services: newServices })
  }

  function handleServiceAdd() {
    const newService = {
      id: uuidv4(),
      service: "",
    }

    handleChange({ services: [...merchantDetails.services, newService] })
  }

  function handleServiceDelete(id) {
    handleChange({
      services: merchantDetails.services.filter(i => i.id !== id),
    })
  }

  function handleHiddenFee(changes) {
    //console.log(changes)
    handleChange({
      paymentGatewaySettings: {
        ...merchantDetails.paymentGatewaySettings,
        ...changes,
      },
    })
  }

  const onSubmit = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      toggleTabProgress(activeTabProgress + 1)
      //handleFileUpload()
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label for="logo">
                Logo{" "}
                <small>
                  ( Requirements: 200px by 200px - less than 50kb - jpeg & png
                  only)
                </small>
              </Label>
              {/* <FileUpload/> */}

              <input
                //value={merchantDetails.Logo}
                className="form-control"
                type="file"
                id="logo"
                name="logo"
                onChange={e => handleLogo(e)}
                //ref={register({ required: true })}
              />

              {errors.Logo && errors.Logo.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
              {isMobile ? (
                <div className="color-primary mt-2 item-center">
                  <h5>
                    <a href={merchantDetails.logo} target="_blank">
                      logo link!
                    </a>
                  </h5>
                </div>
              ) : (
                <div className="color-primary">
                  <a href={merchantDetails.logo} target="_blank">
                    logo link!
                  </a>
                </div>
              )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label for="banner">
                Marketing Banner{" "}
                <small>
                  ( Requirements: 865px by 270px - less than 150kb - jpeg & png
                  only)
                </small>
              </Label>
              {/* <FileUpload/> */}

              <input
                //value={merchantDetails.banner}
                className="form-control"
                type="file"
                id="banner"
                name="banner"
                onChange={e => handleBanner(e)}
                //ref={register({ required: true })}
              />
              {errors.Banner && errors.Banner.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
              {isMobile ? (
                <div className="color-primary mt-2 item-center">
                  <h5>
                    <a href={merchantDetails.banner} target="_blank">
                      banner link!
                    </a>
                  </h5>
                </div>
              ) : (
                <div className="color-primary">
                  <a href={merchantDetails.banner} target="_blank">
                    banner link!
                  </a>
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <FormGroup>
              <Label>Link Validity</Label>
              <select
                value={merchantDetails?.paymentGatewaySettings?.linkValidity}
                className="custom-select"
                name="linkValidity"
                id="linkValidity"
                onChange={e => {
                  //console.log(e.target.value)
                  handleChangePRSettings({
                    linkValidity: parseInt(e.target.value),
                  })
                }}
                ref={register({ required: true })}
              >
                <option value="" selected="selected">
                  Choose here
                </option>
                <option value="24">1 Day</option>
                <option value="168">1 Week</option>
                <option value="730">1 Month</option>
                <option value="8760">1 Year</option>
              </select>
              {errors.LinkValidity &&
                errors.LinkValidity.type === "required" && (
                  <small className="color-danger">This is required</small>
                )}
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              {/* <Input
                type="switch"
                checked={merchantDetails?.paymentGatewaySettings?.feeHidden}
                onClick={() => {
                  handleChange({
                    FeeHidden:
                      !merchantDetails?.paymentGatewaySettings?.feeHidden,
                  })
                }}
              /> */}
              {isMobile ? (
                <div className="item-center mt-2">
                  <Label>Show Additional Fees</Label>
                </div>
              ) : (
                <Label>Show Additional Fees</Label>
              )}

              <br />
              {merchantDetails?.paymentGatewaySettings?.feeHidden ? (
                <i
                  className="bx bxs-toggle-left color-active pointer"
                  style={{ fontSize: "40px" }}
                  onClick={() => {
                    handleHiddenFee({
                      feeHidden:
                        !merchantDetails?.paymentGatewaySettings?.feeHidden,
                    })
                  }}
                >
                  {" "}
                  <p
                    className="pointer"
                    style={{ fontSize: "15px", color: "black" }}
                  >
                    Convenience Fee
                  </p>
                </i>
              ) : (
                // <div
                //   className="pointer"

                // >
                <i
                  className="bx bxs-toggle-right color-danger pointer"
                  style={{ fontSize: "40px" }}
                  onClick={() => {
                    // console.log("click")
                    handleHiddenFee({
                      feeHidden:
                        !merchantDetails?.paymentGatewaySettings?.feeHidden,
                    })
                  }}
                >
                  <p
                    className="pointer"
                    style={{ fontSize: "15px", color: "black" }}
                  >
                    Convenience Fee
                  </p>
                </i>
                // </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <hr></hr>
            {isMobile ? (
              <>
                <div className="item-center">
                  <b>Notification Emails</b>
                </div>
                <div className="item-center">
                  <p>Only the listed emails with receive the notifications.</p>
                </div>
              </>
            ) : (
              <>
                <b>Notification Emails</b>
                <p>Only the listed emails with receive the notifications.</p>
              </>
            )}

            {merchantDetails.notificationEmails &&
              merchantDetails.notificationEmails.map(
                (notificationEmail, index) => (
                  <NotificationEmails
                    index={index}
                    key={index}
                    notificationEmail={notificationEmail}
                    handleNotificationChange={handleNotificationChange}
                    handleNotificationDelete={handleNotificationDelete}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    register={register}
                    errors={errors}
                  />
                )
              )}

            {isMobile ? (
              <Button
                onClick={() => handleNotificationAdd()}
                color="success"
                className="mt-1"
                style={{ width: "100%" }}
              >
                Add Notification Email
              </Button>
            ) : (
              <Button
                onClick={() => handleNotificationAdd()}
                color="success"
                className="mt-1"
              >
                Add Notification Email
              </Button>
            )}
          </Col>

          <Col lg={6}>
            <hr></hr>
            {isMobile ? (
              <>
                <div className="item-center">
                  <b>Products/Services Offered</b>
                </div>
                <div className="item-center">
                  {" "}
                  <p>
                    Please specify the products & services the merchant is
                    offering. ( E.g Flights, Hotels, Visa Processing .... )
                  </p>
                </div>
              </>
            ) : (
              <>
                {" "}
                <b>Products/Services Offered</b>
                <p>
                  Please specify the products & services the merchant is
                  offering. ( E.g Flights, Hotels, Visa Processing .... )
                </p>
              </>
            )}

            {merchantDetails.services &&
              merchantDetails.services.map((service, index) => (
                <Services
                  index={index}
                  key={index}
                  service={service}
                  handleServiceChange={handleServiceChange}
                  handleServiceDelete={handleServiceDelete}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  register={register}
                  errors={errors}
                />
              ))}

            {isMobile ? (
              <Button
                onClick={() => handleServiceAdd()}
                color="success"
                className="mt-1"
                style={{ width: "100%" }}
              >
                Add Service
              </Button>
            ) : (
              <Button
                onClick={() => handleServiceAdd()}
                color="success"
                className="mt-1"
              >
                Add Service
              </Button>
            )}
          </Col>
        </Row>

        <ul className="pager wizard twitter-bs-wizard-pager-link">
          <li
            className={
              activeTabProgress === 1 ? "previous disabled" : "previous"
            }
          >
            <Button
              color="primary"
              onClick={() => {
                toggleTabProgress(activeTabProgress - 1)
              }}
            >
              {" "}
              Previous{" "}
            </Button>
          </li>
          <li className={activeTabProgress === 5 ? "next" : "next"}>
            {activeTabProgress === 5 ? (
              <>{loadSubmitNowButton()}</>
            ) : (
              <>
                <Button type="submit" color="primary" id="my-form_submit_2">
                  {" "}
                  Next{" "}
                </Button>
              </>
            )}
          </li>
        </ul>
      </Form>
    </div>
  )
}

export default SecondConfigurations
