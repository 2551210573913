import React, { useState, useEffect } from "react"
import FileUpload from "../FileUpload"
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Button,
  InputGroup,
} from "reactstrap"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import { connect } from "react-redux"
import { useDispatch } from "react-redux"
import { fileUpload } from "store/actions"

const SecondConfiguration = props => {
  const {
    toggleTabProgress,
    activeTabProgress,
    loadSubmitNowButton,
    handlePaymentRequestChange,
    prDetails,
    merchant,
  } = props
  const dispatch = useDispatch()
  const [selectedFiles, setselectedFiles] = useState([])
  const { register, handleSubmit, errors, reset, watch } = useForm()
  const [cusomtExpiration, setCustomExpiration] = useState(false)
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  useEffect(() => {
    //console.log(prDetails)
  }, [prDetails])
  useEffect(() => {}, [selectedFiles])

  useEffect(() => {
    if (
      prDetails.CustomerDetails.Name !== undefined &&
      prDetails.CustomerDetails.Name !== ""
    ) {
      prDetails.EmailSettings.SubjectLine =
        "Payment Request for " + prDetails.CustomerDetails.Name
    }
  }, [prDetails.CustomerDetails.Name])

  useEffect(() => {}, [prDetails.EmailSettings.SubjectLine])

  useEffect(() => {
    if (
      props.files !== undefined &&
      props.fileError !== "File(s) not attached!"
    ) {
      toggleTabProgress(activeTabProgress + 1)
    }
    if (props.fileError === "File(s) not attached!") {
    }
  }, [props.files, props.filesUploaded, props.fileError])

  function handleChange(changes) {
    handlePaymentRequestChange({ ...prDetails, ...changes })
  }

  function handleChangeEmailDetails(changes) {
    handleChange({ EmailSettings: { ...prDetails.EmailSettings, ...changes } })
  }
  function handleChangeEmailDetails(changes) {
    handleChange({ EmailSettings: { ...prDetails.EmailSettings, ...changes } })
  }
  function handleChangePRSettings(changes) {
    handleChange({
      PaymentRequestSettings: {
        ...prDetails.PaymentRequestSettings,
        ...changes,
      },
    })
  }

  const onSubmit = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      toggleTabProgress(activeTabProgress + 1)
    }
  }

  function handleFileUpload() {
    if (selectedFiles.length !== 0) {
      const formData = new FormData()
      for (var i = 0; i < selectedFiles.length; i++) {
        formData.append("file", selectedFiles[i])
        formData.append("FileName", selectedFiles[i].name)
      }
      if (formData) {
        dispatch(fileUpload(formData))
      }
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="6">
            {isMobile ? (
              <p className="item-center" style={{ color: "#5c8FEC" }}>
                <b>EMAIL & FILE SETTINGS</b>
              </p>
            ) : (
              <p style={{ color: "#5c8FEC" }}>
                <small>
                  <b>EMAIL & FILE SETTINGS</b>
                </small>
              </p>
            )}

            <div className="form-floating mb-3">
              <input
                type="text"
                // readOnly
                className="form-control"
                id="name"
                name="Subject"
                placeholder="Enter Subject"
                value={prDetails.EmailSettings.SubjectLine}
                onChange={e =>
                  handleChangeEmailDetails({ SubjectLine: e.target.value })
                }
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />
              <small className="color-danger">
                {errors.Subject && errors.Subject.message}
              </small>

              <label htmlFor="floatingnameInput" className="pl-0">
                {" "}
                Email Subject
              </label>
            </div>
          </Col>
          <Col lg="6">
            {isMobile ? (
              <p className="item-center" style={{ color: "#5c8FEC" }}>
                <b>Payment Request Settings</b>
              </p>
            ) : (
              <p style={{ color: "#5c8FEC" }}>
                <small>
                  <b>Payment Request Settings</b>
                </small>
              </p>
            )}

            <div className="form-floating mb-3">
              <select
                value={prDetails?.PaymentRequestSettings?.LinkValidity}
                className="form-select form-control"
                name="LinkValidity"
                onChange={e => {
                  //console.log(e.target.value)
                  handleChangePRSettings({
                    LinkValidity: parseInt(e.target.value),
                  })
                }}
                ref={register({ required: true })}
              >
                <option value="" selected="selected">
                  Choose here
                </option>
                <option value="24">1 Day</option>
                <option value="168">1 Week</option>
                <option value="730">1 Month</option>
                <option value="8760">1 Year</option>
              </select>
              <label htmlFor="floatingSelectGrid" className="">
                Link Expiration
              </label>
              {errors.Language && errors.Language.type === "required" && (
                <small className="color-danger">This is required</small>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            {isMobile ? (
              <p className="item-center mt-2">
                <span>
                  Upload up to 3 files only (PDF, PNG, JPEG) with 10mb size each
                  <label className="color-danger">*</label>{" "}
                </span>
              </p>
            ) : (
              <p>
                <small>
                  Upload up to 3 files only (PDF, PNG, JPEG) with 10mb size each
                  <label className="color-danger">*</label>{" "}
                </small>
              </p>
            )}

            <FileUpload
              prDetails={prDetails}
              onChange={value => setselectedFiles(value)}
            />
          </Col>
        </Row>

        <ul className="pager wizard twitter-bs-wizard-pager-link">
          <li
            className={
              activeTabProgress === 1 ? "previous disabled" : "previous"
            }
          >
            <Button
              color="primary"
              onClick={() => {
                toggleTabProgress(activeTabProgress - 1)
              }}
            >
              {" "}
              Previous{" "}
            </Button>
          </li>
          <li className={activeTabProgress === 3 ? "next" : "next"}>
            {activeTabProgress === 3 ? (
              <>{loadSubmitNowButton()}</>
            ) : (
              <Button
                type="submit"
                color="primary"
                id="my-form_submit_1"
                onClick={() => handleFileUpload()}
              >
                {" "}
                Next{" "}
              </Button>
            )}
          </li>
        </ul>
      </Form>
    </>
  )
}

const mapStateToProps = state => {
  return {
    files: state.paymentRequests.files,
    filesUploaded: state.paymentRequests.filesUploaded,
    fileError: state.paymentRequests.error,
  }
}
export default connect(mapStateToProps)(SecondConfiguration)
