import LoadingSpinnerThreeDots from "helpers/commonhelpers/loadingSpinner"
import React from "react"
import { Modal } from "reactstrap"

const LoadingModal = props => {
  const { isOpen, toggle } = props

  return (
    <>
      <Modal
        className="show"
        backdrop="static"
        isOpen={isOpen}
        autoFocus={true}
        centered={true}
        toggle={toggle}
        tabindex="-1"
        size="sm"
      >
        {/* // style={{ backgroundColor: "rgba(0,0,0,.0001) !important" }} */}

        <div style={{ padding: "50px" }}>
          <h2>Processing</h2> <LoadingSpinnerThreeDots />
        </div>
      </Modal>
    </>
  )
}

export default LoadingModal
