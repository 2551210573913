import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import { Col, Row } from "reactstrap"

const Footer = props => {
  const { user, merchant } = props
  return (
    <>
      <Col lg="12">
        <div className="justify-content-center container-alignment">
          {/* <Row> <Col lg={12}>{props.t("For assistance please contact")}</Col></Row> */}
          <Row>
            {/* <Col lg={4}>
            <p>
            <br></br>
                        <b>{user?.firstName} {user?.lastName}</b><br></br>
                        {user?.email}<br></br>
                        {merchant?.phoneNumber}<br></br>
                    </p> 
            </Col>
            <Col lg={4}>
            <p>
                            <br></br>
                            <b>{merchant?.merchantName}</b><br></br>
                            {merchant?.address}<br></br>
                            {merchant?.city +"," + merchant?.country} <br></br>
                        </p> 
                        
            </Col> */}
            <Col lg={12}>
              <img
                src="/logo_blue.png"
                alt="PayAllLink Logo"
                className="img-fluid"
                height="auto"
                maxwidth="60%"
                width="150px"
              />
            </Col>
          </Row>
        </div>
      </Col>
    </>
  )
}

Footer.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Footer))
