import React from "react"
import { Col } from "reactstrap"
//import banner from "../../assets/images/merchant/abcbanner.jpg"

const FooterBanner = props => {
  const path = "/merchant/"
  return (
    <>
      <Col lg="12">
        <br></br>
        <img
          src={props.banner}
          // src={path + props.banner}
          alt=""
          className="img-fluid"
          height="auto"
          maxwidth="100%"
        />
      </Col>
    </>
  )
}

export default FooterBanner
