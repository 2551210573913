import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import ServicesOffered from "./ServicesOffered"
import { Col, Table, Row, CardTitle } from "reactstrap"
const MoreInformation = props => {
  const { servicesOffered, remarks, user, merchant } = props
  return (
    <>
      <Col lg="12">
        {/* <div 
                    // style={{ padding: "1px", borderTop: "dotted", borderWidth: "1px", alignItems: "left", display: "flex", borderColor: "#ced4da" }}
                    >
                        <Col lg="4">

                            <small> <b>{props.t("Remarks")} </b> </small><br></br>
                            <span>{remarks ? remarks : props.t("N/A")}</span>

                        </Col>
                        <Col lg="4">

                            <small> <b>{props.t("Services Offered")}</b>  </small>
                            <ServicesOffered servicesOffered={servicesOffered} />

                        </Col>
                        <Col lg="4">

                            <small>{props.t("For assistance please contact")}</small>
                            <p>{remarks ? remarks : props.t("N/A")}</p>

                        </Col>

                    </div> */}

        <Table
          className="table table-sm  table-borderless"
          // style={{ padding: "1px", borderTop: "dotted", borderWidth: "1px", alignItems: "left", display: "flex", borderColor: "#ced4da" }}
        >
          <tbody>
            <tr>
              <td className="tdDetails">
                <small>
                  {" "}
                  <b>{props.t("Remarks")} </b>{" "}
                </small>
              </td>
              <td className="tdDetails">
                {" "}
                <small>{remarks ? remarks : props.t("N/A")}</small>
              </td>
            </tr>
            <tr>
              <td className="tdDetails">
                <small>
                  {" "}
                  <b>{props.t("Services Offered")}</b>{" "}
                </small>
              </td>
              <td className="tdDetails">
                <ServicesOffered servicesOffered={servicesOffered} />
              </td>
            </tr>
            <tr>
              <td className="tdDetails">
                <small>
                  <b>{props.t("For assistance please contact")}</b>
                </small>
              </td>
              <td className="tdDetails">
                <small>
                  {user?.firstName} {merchant?.phoneNumber}{" "}
                </small>
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </>
  )
}

MoreInformation.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MoreInformation))
