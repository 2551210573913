import React, { useEffect, useState, useContext } from "react"
import { connect } from "react-redux"
import { Col, Form, Row, Button } from "reactstrap"
import { OfficeIds } from "../OfficeIds"
import { v4 as uuidv4 } from "uuid"
import { PaymentGateways } from "../PaymentGateways"
import { useForm } from "react-hook-form"
import { isEmpty } from "lodash"
import { MerchantCreateContext } from "../index"
import paymentGateway from "store/paymentgateway/reducer"
import AuthUser from "components/AuthUser/AuthUser"
import EnabledPaymentOptionv2 from "../EnabledPaymentOptionv2"
import { PaymentListSelectionMerchant } from "../../../components/PaymentTypesSelect/PaymentList"
export const ThirdPaymentGateway = props => {
  const {
    handleMerchantChange,
    merchantDetails,
    PaymentGatewayList,
    loadSubmitNowButton,
    toggleTabProgress,
    activeTabProgress,
  } = props

  const { register, handleSubmit, errors, reset, watch } = useForm()
  const { settingThirdStepError } = useContext(MerchantCreateContext)
  const user = useState(AuthUser())
  const [options, setOptions] = useState()
  const [optionsSort, setOptionsSort] = useState()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  useEffect(() => {
    if (Array.isArray(merchantDetails.paymentGatewayEnrollments)) {
      merchantDetails.paymentGatewayEnrollments.map((pg, index) => {
        if (pg.id === undefined) {
          pg.id = uuidv4()
        }
      })
    }
  }, [merchantDetails.paymentGatewayEnrollments])
  useEffect(() => {
    if (Array.isArray(merchantDetails.officeIds)) {
      merchantDetails.officeIds.map((oid, index) => {
        if (oid.id === undefined) {
          oid.id = uuidv4()
        }
      })
    }
  }, [merchantDetails.officeIds])
  useEffect(() => {
    //console.log(optionsSort)
  }, [options])
  useEffect(() => {
    //console.log(merchantDetails.enabledPaymentOptions)
    if (merchantDetails?.paymentGatewaySettings?.enabledPaymentOptions) {
      //setOptionsSort(merchantDetails.enabledPaymentOptions)
      setOptions(
        PaymentListSelectionMerchant(
          merchantDetails.paymentGatewayEnrollments,
          merchantDetails?.paymentGatewaySettings?.enabledPaymentOptions
        )
      )
    }
  }, [merchantDetails?.paymentGatewaySettings?.enabledPaymentOptions])
  function handleChange(changes) {
    //console.log(changes)
    handleMerchantChange({ ...merchantDetails, ...changes })
  }
  function handlePaymentSettingsSelco(changes) {
    // let array = merchantDetails?.paymentGatewaySettings?.enabledPaymentOptions
    // let array2 = merchantDetails?.paymentGatewaySettings?.selcoPaymentOption

    // array2 = array
    // merchantDetails?.paymentGatewaySettings?.selcoPaymentOption= array2
    handleChange({
      paymentGatewaySettings: {
        ...merchantDetails?.paymentGatewaySettings,
        ...changes,
      },
    })
  }
  function handleOfficeIdChange(id, officeId) {
    const newOfficeIds = [...merchantDetails.officeIds]
    const index = newOfficeIds.findIndex(i => i.id === id)
    newOfficeIds[index] = officeId
    handleChange({ officeIds: newOfficeIds })
  }
  function handlePaymentOptionChanges(changes, boolCheck) {
    //console.log(changes)
    let options = handleUnquiePaymentType(
      changes,
      merchantDetails?.paymentGatewaySettings.enabledPaymentOptions
    )
    // let valueCheck = handleUnquiePaymentTypeValue(
    //   changes,
    //   merchantDetails?.paymentGatewaySettings.enabledPaymentOptions
    // )
    //console.log(boolCheck)
    if (!options.toString().includes(changes) && !boolCheck) {
      options.push(changes)
    }

    setOptions(
      PaymentListSelectionMerchant(
        merchantDetails.paymentGatewayEnrollments,
        options.sort()
      )
    )

    handleChange({ enabledPaymentOptions: options.sort() })
    //handleSelcoChange(options.sort())
    handlePaymentSettingsSelco({ selcoPaymentOption: options.sort() })
  }

  function handleUnquiePaymentType(value, enabledPaymentOptions) {
    let arrayOptions = enabledPaymentOptions

    arrayOptions.map((op, index) => {
      if (op.toString().slice(1) === value.toString().slice(1)) {
        arrayOptions.splice(index, 1)
      }
    })
    //console.log(arrayOptions)
    return arrayOptions
  }

  function handleOfficeIdAdd() {
    const newOfficeId = {
      id: uuidv4(),
      officeId: "",
      date: new Date().toISOString(),
    }
    handleChange({ officeIds: [...merchantDetails.officeIds, newOfficeId] })
  }

  function handleOfficeIdDelete(id) {
    handleChange({
      officeIds: merchantDetails.officeIds.filter(i => i.id !== id),
    })
  }

  function handlePaymentGatewayChange(id, paymentGateway) {
    const newPaymentGateways = [...merchantDetails.paymentGatewayEnrollments]
    const index = newPaymentGateways.findIndex(i => i.id === id)
    newPaymentGateways[index] = paymentGateway
    handleChange({ paymentGatewayEnrollments: newPaymentGateways })
  }

  function handlePaymentGatewayAdd() {
    const newPaymentGateway = {
      id: uuidv4(),
      paymentGateway: "",
      isActive: false,
      paymentGatewayId: "",
      commissionSettings: [
        {
          id: uuidv4(),
          basis: "",
          fixed: 0,
          percentage: 0,
        },
      ],
      customConfig: [
        {
          id: uuidv4(),
          key: "",
          value: "",
        },
      ],
    }
    handleChange({
      paymentGatewayEnrollments: [
        ...merchantDetails.paymentGatewayEnrollments,
        newPaymentGateway,
      ],
    })
  }

  function handlePaymentGatewayDelete(id) {
    handleChange({
      paymentGatewayEnrollments:
        merchantDetails.paymentGatewayEnrollments.filter(i => i.id !== id),
    })
  }

  const onSubmit = data => handleCheckNoErrors()

  function handleCheckNoErrors() {
    if (isEmpty(errors)) {
      toggleTabProgress(activeTabProgress + 1)
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="6">
            {merchantDetails.type &&
              merchantDetails.type === "Travel Agency" &&
              user[0]?.role === "SuperAdmin" && (
                <>
                  <b>Add OfficeIds</b>
                  <p></p>
                  {merchantDetails.officeIds &&
                    merchantDetails.officeIds.map((officeId, index) => (
                      <OfficeIds
                        index={index}
                        key={index}
                        officeId={officeId}
                        handleOfficeIdChange={handleOfficeIdChange}
                        handleOfficeIdDelete={handleOfficeIdDelete}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        register={register}
                        errors={errors}
                      />
                    ))}

                  <Button
                    onClick={() => handleOfficeIdAdd()}
                    color="success"
                    className="mt-1"
                  >
                    Add New OfficeId
                  </Button>
                  <hr></hr>
                </>
              )}
          </Col>
        </Row>

        {isMobile ? (
          <>
            <div className="item-center">
              {" "}
              <b>Active Payment Types</b>
            </div>
            <div className="item-center mb-2">
              <p>
                Select the Payment Types your customers would be able to pay
                with.
              </p>
            </div>
          </>
        ) : (
          <>
            {" "}
            <b>Active Payment Types</b>
            <p>
              Select the Payment Types your customers would be able to pay with.
            </p>
          </>
        )}

        <EnabledPaymentOptionv2
          options={options}
          handlePaymentOptionChanges={handlePaymentOptionChanges}
          //pgList={merchantDetails.paymentGatewayEnrollments}
        />
        <hr></hr>
        {isMobile ? (
          <>
            <div className="item-center my-2">
              {" "}
              <b>Add Default Payment Gateway</b>
              <p></p>
            </div>
          </>
        ) : (
          <>
            {" "}
            <b>Add Default Payment Gateway</b>
            <p></p>
          </>
        )}

        {merchantDetails.paymentGatewayEnrollments &&
          merchantDetails.paymentGatewayEnrollments?.map(
            (paymentGateway, index) => (
              <PaymentGateways
                index={index}
                paymentGatewayList={PaymentGatewayList}
                key={index}
                enabledPaymentOptions={
                  merchantDetails?.paymentGatewaySettings?.enabledPaymentOptions
                }
                paymentGateway={paymentGateway}
                handlePaymentGatewayChange={handlePaymentGatewayChange}
                handlePaymentGatewayDelete={handlePaymentGatewayDelete}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                register={register}
                errors={errors}
              />
            )
          )}
        {user[0]?.role === "SuperAdmin" ? (
          <Button
            onClick={() => handlePaymentGatewayAdd()}
            color="success"
            className="mt-1"
            style={{ width: isMobile ? "100%" : "" }}
          >
            Add Payment Gateway
          </Button>
        ) : null}

        <ul className="pager wizard twitter-bs-wizard-pager-link">
          <li
            className={
              activeTabProgress === 1 ? "previous disabled" : "previous"
            }
          >
            <Button
              color="primary"
              onClick={() => {
                toggleTabProgress(activeTabProgress - 1)
              }}
            >
              {" "}
              Previous{" "}
            </Button>
          </li>
          <li className={activeTabProgress === 5 ? "next" : "next"}>
            {activeTabProgress === 5 ? (
              <>{loadSubmitNowButton()}</>
            ) : (
              <Button type="submit" color="primary" id="my-form_submit_3">
                {" "}
                Next{" "}
              </Button>
            )}
          </li>
        </ul>
      </Form>
    </div>
  )
}

ThirdPaymentGateway.propTypes = {}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPaymentGateway)
