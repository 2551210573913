import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Col, FormGroup, Row, Button, Form } from "reactstrap"
function ConvenienceFeeSettings(props) {
  const {
    convenience,
    handleConvenienceChange,
    handleConvenienceDelete,
    transactionBasis,
    handleSubmit,
    onSubmit,
    register,
    errors,
    index,
    indexPG,
  } = props
  const inputBasis = "basis" + indexPG + index
  const inputType = "type" + indexPG + index
  const inputValue = "value" + indexPG + index
  const [convenienceType, SetType] = useState("Fixed")
  const [convenienceValue, SetValue] = useState()
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  function handleChange(changes) {
    // console.log(changes)
    handleConvenienceChange(convenience.id, { ...convenience, ...changes })
  }
  useEffect(() => {
    // console.log(transaction.Basis)
    if (convenience) {
      if (parseFloat(convenience.Fixed) !== 0) {
        SetType("Fixed")
        SetValue(convenience.Fixed)
      }
      if (parseFloat(convenience.Percentage) !== 0) {
        SetType("Percentage")
        SetValue(convenience.Percentage)
      }
    }
    // console.log(transaction)
  }, [convenience])
  useEffect(() => {
    if (convenienceType && convenienceValue) {
      switch (convenienceType) {
        case "Fixed":
          handleChange({ Fixed: parseFloat(convenienceValue) || "" })
          break
        case "Percentage":
          handleChange({ Percentage: parseFloat(convenienceValue) || "" })
          break
      }
    }
  }, [convenienceType, convenienceValue])
  return (
    <div
      style={{
        backgroundColor: "#b7c6e7",
        marginLeft: isMobile ? "" : "20px",
        padding: "10px",
      }}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col lg="3">
            {/* TODO: pg get from db*/}
            <FormGroup>
              <label htmlFor="subject">Payment Type </label>

              <input
                value={convenience.Basis}
                name={inputBasis}
                onChange={e => handleChange({ Basis: e.target.value })}
                className="form-control"
                type="text"
                placeholder={"credit/debit/applepay"}
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              />
              <small className="color-danger">
                {errors[inputBasis] && errors[inputBasis].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label htmlFor="subject">Type </label>
              <select
                value={convenienceType}
                name={inputType}
                onChange={e => SetType(e.target.value)}
                className="form-control"
                ref={register({
                  required: {
                    value: true,
                    message: "This is required",
                  },
                })}
              >
                <option value={"Fixed"}>Fixed</option>
                <option value={"Percentage"}>Percentage</option>
              </select>
              <small className="color-danger">
                {errors[inputType] && errors[inputType].message}
              </small>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <label htmlFor="subject">Value </label>
              {isMobile ? (
                <Row>
                  <Col>
                    <input
                      value={convenienceValue}
                      name={inputValue}
                      step="0.01"
                      onChange={e => SetValue(parseFloat(e.target.value))}
                      className="form-control"
                      type="number"
                      placeholder={"0"}
                      ref={register({
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                    />
                  </Col>
                  <Col className="py-2 ml-0 pl-0 ">
                    {" "}
                    {convenienceType === "Fixed" ? <b>QAR</b> : <b>%</b>}
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col sm={8}>
                    <input
                      value={convenienceValue}
                      name={inputValue}
                      step="0.01"
                      onChange={e => SetValue(parseFloat(e.target.value))}
                      className="form-control"
                      type="number"
                      placeholder={"0"}
                      ref={register({
                        required: {
                          value: true,
                          message: "This is required",
                        },
                      })}
                    />
                  </Col>
                  <Col sm={4} className="py-2 ml-0 pl-0 ">
                    {" "}
                    {convenienceType === "Fixed" ? <b>QAR</b> : <b>%</b>}
                  </Col>
                </Row>
              )}

              <small className="color-danger">
                {errors[inputValue] && errors[inputValue].message}
              </small>
            </FormGroup>
          </Col>

          <Col lg="3">
            <FormGroup>
              <label style={{ color: "#b7c6e7" }} htmlFor="subject">
                Delete{" "}
              </label>

              <Button
                onClick={e => handleConvenienceDelete(convenience.id)}
                color="secondary"
                className="btn-block inner"
                style={{ width: "100%" }}
              >
                {" "}
                Delete Fee{" "}
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ConvenienceFeeSettings
